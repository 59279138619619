import Button from "react-bootstrap/Button"; //login form coded by Umar
import { Form, Alert } from "react-bootstrap";
import { useState } from "react";
import { signInWithEmailAndPassword } from "@firebase/auth";
import { doc, getDoc } from "@firebase/firestore";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { ERRORS } from "constants";
import { auth, firestore } from "../../configs/firebase";
import { loginUser } from "redux/Slices/AuthSlice";
import { setUser } from "redux/Slices/UserSlice";
import "./formStyle.css";
// import "./formstyles.css";
import "fonts/fonts.css";
import Spinner from "react-bootstrap/Spinner";

function LoginForm({ submitHandler }) {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const {
    //react hook form
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  async function getUserDataFromFirestore(uid) {
    try {
      const userRef = doc(firestore, "users", uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        return { id: userDoc.id, ...userDoc.data() };
      } else {
        throw new Error({ code: 404, message: "User Not Found" });
      }
    } catch (error) {
      console.log("ERROR IIIS:", error);
      throw error;
    }
  }

  const handlePinOrEmail = (val) => {
    const emailRegex = /^[a-zA-Z0-9._-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(val);
  }

  //submit handler
  const handleSignin = async (data, e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        handlePinOrEmail(data.email) ? data.email : data.email + '.' + process.env.REACT_APP_SUB_DOMAIN + '@orixa.com',
        data.password
      );
      const { providerData, uid } = userCredential.user;
      const userData2 = await getUserDataFromFirestore(uid);
      const user = {
        email: providerData[0].email,
        ...userData2,
      };
      //checking logging in state
      dispatch(loginUser(user));
      //dispatching the action to save data in redux store
      dispatch(setUser(user));
      submitHandler(user);
    } catch (error) {
      let errorMessage = "";
      if (
        error.code === ERRORS.ERROR_USER_NOT_FOUND ||
        error.code === ERRORS.ERROR_INVALID_EMAIL ||
        error.code === ERRORS.ERROR_WRONG_PASSWORD
      ) {
        errorMessage = "Incorrect Pin or Password";
      } else if (error.code === ERRORS.ERROR_NETWORK_REQ_FAILED) {
        errorMessage = "Network Error";
      } else if (error.code === ERRORS.ERROR_TOO_MANY_REQUESTS) {
        errorMessage =
          "Too many unsuccessful sign-in attempts. Please try again later.";
      } else if (error.code === 404) {
        errorMessage = error?.message;
      } else {
        errorMessage = "An unknown error occurred.Try again later.";
      }
      setError(errorMessage);
      // setError(err);
    }
  };

  return (
    <div>
      {/* <Form
        className={`form-container ${
          errors.password || errors.email || error ? "error" : ""
        }`}
        onSubmit={handleSubmit(handleSignin)}
      >
        
        <Form.Group controlId="formBasicEmail" className="form-group">
          <Form.Label
            style={{
              display: "flex",
              flexDirection: "column",
              fontFamily: "Montserrat",
              fontSize: "16px",
              marginRight: "12px",
              alignItems: "centre",
              justifyContent:
                error !== "" || errors.password || errors.email
                  ? "space-between"
                  : "center" ,
              paddingTop:  error !== "" || errors.password || errors.email
              ? "10px"
              : "6px",
              color:'#b1b1b1'

            }}
          >
            Pin
          </Form.Label>
          <div className="input-container">
            <Form.Control
              className={`input-control ${error ? "error" : ""}`}
              // style={errors.password || error ?{  height: "80%" }:{  height: "100%" }}
              type="email"
              placeholder=""
              name="email"
              {...register("email", {
                required: "PIN is required",
              })}
              onChangeCapture={() => setError("")}
            />
            {errors.email && (
              <p className="text-danger">{errors.email.message}</p>
            )}
            {error && <p className="text-danger">{error}</p>}
          </div>

          <Form.Text></Form.Text>
        </Form.Group>
        <Form.Group controlId="formBasicPassword" className="form-group-sec">
          <Form.Label
            style={{
              display: "flex",
              flexDirection: "column",
              fontFamily: "Montserrat",
              fontSize: "16px",
              marginRight: "12px",
              alignItems: "centre",
              justifyContent:
                error !== "" || errors.password || errors.email
                  ? "space-between"
                  : "center" ,
              paddingTop:  error !== "" || errors.password || errors.email
              ? "10px"
              : "6px",
              color:'#b1b1b1'
            }}
          >
            Password
          </Form.Label>
          <div className="input-container">
            <Form.Control
              className={`input-control ${error ? "error" : ""}`}
              // style={errors.password || error ?{  height: "80%" }:{  height: "100%" }}
              type="password"
              placeholder=""
              name="password"
              {...register("password", {
                required: "Password is required",
                minLength: {
                  value: 6,
                  message: `Invalid Password`,
                },
                maxLength: {
                  value: 25,
                  message: `Invalid Password`,
                },
              })}
              onChangeCapture={() => setError("")}
            />
            {errors.password && (
              <div>
                <p className="text-danger">{errors.password.message}</p>
              </div>
            )}
          </div>
          <Form.Text></Form.Text>
        </Form.Group>
        <button
          className={`form-button ${
            errors.password || errors.email || error ? "error" : ""
          }`}
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <>
              <Spinner animation="grow spinner-grow-sm" />
            </>
          ) : (
            <span style={{fontSize:'14px',paddingBottom:'1px'}}>Sign In</span>
          )}
        </button>
      </Form> */}
      <Form
        className="form-container"
        onSubmit={handleSubmit(handleSignin)}
      >
        <div className="form-container-row">
        <Form.Group controlId="formBasicEmail" className="form-group">
          <Form.Label
            style={{
              display: "flex",
              flexDirection: "column",
              fontFamily: "Montserrat",
              fontSize: "16px",
              marginRight: "12px",
              alignItems: "centre",
              justifyContent:
                error !== "" || errors.password || errors.email
                  ? "space-between"
                  : "center" ,
              paddingTop:  error !== "" || errors.password || errors.email
              ? "10px"
              : "6px",
              color:'#b1b1b1'

            }}
          >
            Pin
          </Form.Label>
          <div className="input-container">
            <Form.Control
              className={`input-control ${error ? "error" : ""}`}
              // style={errors.password || error ?{  height: "80%" }:{  height: "100%" }}
              placeholder=""
              name="email"
              {...register("email", {
                required: "Pin Required",
              })}
              onChangeCapture={() => setError("")}
            />
            {errors.email && (
              <p id="error-text"className="text-danger">{errors.email.message}</p>
            )}
          </div>

          <Form.Text></Form.Text>
        </Form.Group>
        <Form.Group controlId="formBasicPassword" className="form-group-sec">
          <Form.Label
            style={{
              display: "flex",
              flexDirection: "column",
              fontFamily: "Montserrat",
              fontSize: "16px",
              marginRight: "12px",
              alignItems: "centre",
              justifyContent:
                error !== "" || errors.password || errors.email
                  ? "space-between"
                  : "center" ,
              paddingTop:  error !== "" || errors.password || errors.email
              ? "10px"
              : "6px",
              color:'#b1b1b1'
            }}
          >
            Password
          </Form.Label>
          <div className="input-container">
            <Form.Control
              className={`input-control ${error ? "error" : ""}`}
              // style={errors.password || error ?{  height: "80%" }:{  height: "100%" }}
              type="password"
              placeholder=""
              name="password"
              {...register("password", {
                required: "Password Required",
                minLength: {
                  value: 6,
                  message: `Invalid Password`,
                },
                maxLength: {
                  value: 25,
                  message: `Invalid Password`,
                },
              })}
              onChangeCapture={() => setError("")}
            />
            {errors.password && (
              <div>
                <p className="text-danger">{errors.password.message}</p>
              </div>
            )}
          </div>
          <Form.Text></Form.Text>
        </Form.Group>
        <button
          className={`form-button ${
            errors.password || errors.email || error ? "error" : ""
          }`}
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <>
              <Spinner animation="grow spinner-grow-sm" />
            </>
          ) : (
            <span style={{fontSize:'14px',paddingBottom:'1px'}}>Sign in</span>
          )}
        </button>
        </div>
        {error && <p className="text-danger" style={{marginLeft:'44px'}}>{error}</p>}
      </Form>
    </div>
  );
}

export default LoginForm;
