import { collection, getDocs, orderBy, query,where } from "@firebase/firestore";
import { firestore } from "configs/firebase";
import { ROLES } from "constants/global";
import { getStorage, ref, listAll} from "firebase/storage";
import { db } from "configs/firebase";

// Function for getting data from Firebase
export const fetchMarkerData = async (role,mode="",startDate=null,endDate=null,viewMode="",imgMode="all",listOfIds=[]) => {
  let admin_query;
  const storage = getStorage(db);
  const listRef = ref(storage, 'capturedImages');
  const res = await listAll(listRef);

  try {
    const document_ref = collection(firestore, process.env.REACT_APP_IMAGE_DETAILS_COLLECTION);
    // if((mode && mode.length>0) && startDate && endDate && (viewMode && viewMode.length>0)){
    
    //   admin_query = query(document_ref, where("capturedImageMode", "==", mode),where("clientVisibility", "==", true), where('dateTime', '>=', startDate),where('dateTime', '<=', endDate),orderBy("dateTime", "desc"));
    //   }
    if((mode && mode.length>0) && startDate && endDate && (viewMode && viewMode.length>0)){
      if(mode === 'waypoint' || mode === 'snapshot') {
        admin_query = query(document_ref, where("capturedImageMode", "==", mode), where("clientVisibility", "==", true), where('dateTime', '>=', startDate),where('dateTime', '<=', endDate),orderBy("dateTime"));
      } else {
        admin_query = query(document_ref,where("clientVisibility", "==", true), where('dateTime', '>=', startDate),where('dateTime', '<=', endDate),orderBy("dateTime"));
      }}
    else if (startDate && endDate && mode.length===0 && viewMode.length!==0) {
        admin_query = query(document_ref,where( "clientVisibility", "==", true), where('dateTime', '>=', startDate),where('dateTime', '<=', endDate),orderBy("dateTime"));
      }
    // else if (startDate && endDate && viewMode.length===0 && mode.length!==0) {
    //     admin_query = query(document_ref,where( "capturedImageMode", "==", mode), where('dateTime', '>=', startDate),where('dateTime', '<=', endDate),orderBy("dateTime", "desc"));
    //   }
    else if (startDate && endDate && viewMode.length===0 && mode.length!==0) {
      if(mode === 'waypoint' || mode === 'snapshot') {
        admin_query = query(document_ref, where("capturedImageMode", "==", mode), where('dateTime', '>=', startDate),where('dateTime', '<=', endDate),orderBy("dateTime"));

      } else {
        admin_query = query(document_ref, where('dateTime', '>=', startDate),where('dateTime', '<=', endDate),orderBy("dateTime"));
      }
    }
    
    else if (startDate && endDate && mode.length===0 && viewMode.length===0) {
        admin_query = query(document_ref, where('dateTime', '>=', startDate),where('dateTime', '<=', endDate),orderBy("dateTime"));
        }
    else{
       admin_query = query(document_ref);
      }
    let querySnapshot; 
    // Query Snapshot for Admin
    if(role===ROLES.ADMIN){
      querySnapshot = await getDocs(admin_query); 
    }else if(role===ROLES.USER){
      // Query Snapshot for USER
      let user_query
      console.log(listOfIds.length)
      if(listOfIds.length!=0){
        user_query=query(document_ref,where('mobileImeiReference', 'in', listOfIds), where('dateTime', '>=', startDate),where('dateTime', '<=', endDate),orderBy("dateTime"))

      }
      else if(listOfIds.length==0){
        user_query=query(document_ref,where('dateTime', '>=', startDate),where('dateTime', '<=', endDate),orderBy("dateTime"))

      }
      // let user_query=query(document_ref,where('dateTime', '>=', startDate),where('dateTime', '<=', endDate),orderBy("dateTime"))
      querySnapshot = await getDocs(user_query); 
    }  
    let fetchedData=[];
    let anotherArray=[];
    let filteredData=[]
    
    if (!querySnapshot.empty) {
      querySnapshot.forEach(doc => {
        const imageRemoteUrl = doc.data().imageRemoteUrl;
      if (imageRemoteUrl!=null) {
        fetchedData.push({id:doc.id, ...doc.data()});
      }
        // fetchedData.push({id:doc.id, ...doc.data()});
      })
     

      let currentIndex = 0;
      // console.log("fetched DaTa is:",fetchedData)
      while (currentIndex < fetchedData.length) {
        //console.log(currentIndex)
        const currentObject = {...fetchedData[currentIndex]};
        if (currentObject.panoramaIndex && currentObject.panoramaIndex == 0) {
          // If panorama index found 0
          let panaromas=[];
          if(fetchedData[currentIndex+1] && fetchedData[currentIndex+1].panoramaIndex && fetchedData[currentIndex+1].panoramaIndex == 1) panaromas.push({...fetchedData[currentIndex+1]});
          if(fetchedData[currentIndex+2] && fetchedData[currentIndex+2].panoramaIndex && fetchedData[currentIndex+2].panoramaIndex == 2) panaromas.push({...fetchedData[currentIndex+2]});
          // let allClientVisible = panaromas.every(obj => obj.clientVisibility && obj.clientVisibility === true);
          let previous;
          if(anotherArray[anotherArray.length-1] && !anotherArray[anotherArray.length-1].panoramaImages)
          previous=anotherArray.pop();
         if(previous && !previous.panoramaImages){
            // If there is previous element
            // console.log("previous logic ran..");
            // console.log(panaromas)

            panaromas.unshift({...currentObject})
            previous.panoramaImages = panaromas;
            previous.capturedImageMode='panorama';
            // previous.clientVisibility=allClientVisible;
            anotherArray.push(previous);
          }else{
            // Ig yhrtr id no previous element
            // console.log(panaromas[0].id)
            // console.log(panaromas[0])
            // console.log(panaromas)
          
            if(currentObject?.id === panaromas[0]?.id){
              panaromas.splice(0, 1);
            }
            currentObject.panoramaImages = panaromas;
            currentObject.capturedImageMode='panorama';
            // currentObject.clientVisibility = allClientVisible;
            anotherArray.push(currentObject);
          }
          if(fetchedData[currentIndex+2] && fetchedData[currentIndex+2].panoramaIndex && fetchedData[currentIndex+2].panoramaIndex == 2){
            currentIndex += 3; // Skip the next two elements
            continue;
          }if(fetchedData[currentIndex+1] && fetchedData[currentIndex+1].panoramaIndex && fetchedData[currentIndex+1].panoramaIndex == 1){
            currentIndex += 2; // Skip the next one elements
            continue;
          }
          currentIndex+=1;
        } else {
          anotherArray.push(currentObject);
          currentIndex++;
        
        }
        // if(imgMode!="all"){
        //   const matchingItems = (role===ROLES.USER)? res?.items.filter((itemRef) =>( currentObject.id === itemRef.fullPath.split('/')[1] && currentObject.clientVisibility!=false)):( role===ROLES.ADMIN && (res?.items.filter((itemRef) => currentObject.id === itemRef.fullPath.split('/')[1] ))) ;
        //   if (matchingItems.length > 0) {
        //     filteredData.push(currentObject);
        //   }
        // }
       
      }
      if (mode.length>0){
        for (let i = anotherArray.length - 1; i >= 0; i--) {
          if(imgMode=='images'){
          const matchingItems=res?.items.filter((itemRef) => itemRef.fullPath.split('/')[1].startsWith(anotherArray[i].id)  )
          console.log(matchingItems)
          if ( matchingItems.length>0 && anotherArray[i].capturedImageMode===mode) {
            filteredData.push(anotherArray[i])
          }

          }
          else if(imgMode=='no-images'){
            const matchingItems=res?.items.filter((itemRef) => itemRef.fullPath.split('/')[1].startsWith(anotherArray[i].id)  )
          if ( matchingItems.length==0 && anotherArray[i].capturedImageMode===mode) {
            filteredData.push(anotherArray[i])
          }
          }
          else if(imgMode=="all" && anotherArray[i].capturedImageMode===mode){
            filteredData.push(anotherArray[i])
          }
          
          
        }
      }
      else if (mode.length===0){
        for (let i = anotherArray.length - 1; i >= 0; i--) {
          if(imgMode=='images'){
            const matchingItems=res?.items.filter((itemRef) => itemRef.fullPath.split('/')[1].startsWith(anotherArray[i].id) )
          if ( matchingItems.length>0) {
            filteredData.push(anotherArray[i])
          }

          }
          else if(imgMode=='no-images'){
          const matchingItems=res?.items.filter((itemRef) => itemRef.fullPath.split('/')[1].startsWith(anotherArray[i].id)  )
          if ( matchingItems.length==0) {
            filteredData.push(anotherArray[i])
          }
          }
        }
        
      }
      
    }
    else {
      console.log("No data found in Firestore");
    }
    if(imgMode=="all" && mode.length==0) {
      return anotherArray
    }
    else {
      return filteredData

    }
    
  }
  catch (err) {
      console.log("Error found while fetching data: ", err);
      throw new Error(err);

    // return err;
  }
}
